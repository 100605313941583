/* eslint-disable react/no-danger */
// @flow
import React from 'react';
import cx from 'classnames';
import styles from '../../styles/pages/landing.module.scss';

const DATA = {
  title:
    'ペイディについて、わからないこと、不安なことはいつでもお問い合わせください。',
  button2text: 'ヘルプ',
  button2link: 'https://cs.paidy.com/',
  mainLink: 'paidy.com',
  copyright: '© Paidy Inc.',
  twitterLink: 'https://twitter.com/paidyjp',
  sysId: '56ieAJuhM3qdYYRVvCsnvM',
};

const Footer = ({ data = DATA }) => {
  return (
    <footer className={styles.footer}>
      <div className={styles.inner}>
        <p className={styles.footer_text}>
          ペイディについて、わからないこと、不安なことは
          <a
            className={cx(styles.footer_btn, styles.sixPay)}
            href={data.button2link}
            rel="noreferrer"
            target="_blank"
          >
            こちらから
          </a>
          お問い合わせください。
        </p>
        <div className={cx(styles.footer_url, styles.sixPay)}>
          <a href={`https://${data.mainLink}`} rel="noreferrer" target="_blank">
            {data.mainLink}
          </a>
        </div>
        <small className={styles.footer_copyright}>{data.copyright}</small>
        <div className={styles.footer_twitter}>
          <a href={data.twitterLink} rel="noreferrer" target="_blank">
            <img src="/landing/icon-x-white.svg" alt="Twitter" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
