import React, { useReducer } from 'react';
import {
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import classnames from 'classnames';
import axios from 'axios';
import Footer from '../../components/Landing/Footer6Pay';
import Layout from '../../components/Layout/Layout';
import SixPayImage from '../../images/merchant/6pay12pay/6pay.jpg';
import TwelvePayImage from '../../images/merchant/6pay12pay/12pay.jpg';
import {
  SITE_METADATA,
  CONTACT_US_ENDPOINT,
  PAIDY_HOST,
} from '../../constants';
import Steps from '../../components/Apple/Steps';
import styles from './6pay12pay.module.scss';

export const SEOProps = {
  title: SITE_METADATA.merchant_6pay12pay.title,
  description: SITE_METADATA.merchant_6pay12pay.description,
};

const FOOTER_DATA = {
  title:
    'ペイディについて、わからないこと、不安なことはいつでもお問い合わせください。',
  button2text: 'ヘルプ',
  button2link: 'https://paidy.com/merchant/materials/faq/',
  mainLink: 'paidy.com',
  copyright: '© Paidy Inc.',
  twitterLink: 'https://twitter.com/paidyjp',
  sysId: '56ieAJuhM3qdYYRVvCsnvM',
};

const StyledRadio = (props) => (
  <Radio
    className={styles.radioButton}
    disableRipple
    color="default"
    checkedIcon={
      <span
        className={classnames(
          styles.radioButtonIcon,
          styles.radioButtonIconChecked
        )}
      />
    }
    icon={<span className={styles.radioButtonIcon} />}
    {...props}
  />
);

const formReducer = (state, action) => {
  switch (action.type) {
    case 'setInit':
      return {
        ...state,
        state: 'init',
        isLoading: false,
        isSubmittingError: false,
      };
    case 'setSubmittingSuccess':
      return { ...state, state: 'success', formData: {} };
    case 'setConfirm':
      return { ...state, state: 'confirm', formData: action.formData };
    case 'setLoading':
      return { ...state, isLoading: true };
    case 'setSubmittingError':
      return { ...state, isSubmittingError: true, isLoading: false };
    default:
      return state;
  }
};

const Confirm = ({
  paymentPlan,
  companyName,
  shopName,
  managerName,
  managerEmail,
  otherQuestion,
  backHandler,
  submitDataHandler,
  isLoading,
  isSubmittingError,
}) => {
  return (
    <div className={styles.confirmWrapper}>
      <div className={styles.confirmContentWrapper}>
        <div
          className={classnames(styles.alert, {
            [styles.error]: isSubmittingError,
          })}
        >
          フォームの送信に失敗しました。お手数ですが、もう一度お試しください。
        </div>
        <div className={styles.confirmMainContent}>
          <h1>お申込み内容のご確認</h1>
          <p className={styles.confirmText}>
            お申込みの前に必ずご確認ください。
          </p>
          <div className={styles.confirmContent}>
            {[
              { label: 'お申し込みの分割回数:', value: paymentPlan },
              { label: '会社名:', value: companyName },
              { label: 'お申し込み加盟店名（ECサイト名）:', value: shopName },
              { label: 'ご担当者名:', value: managerName },
              { label: 'ご担当者メールアドレス:', value: managerEmail },
              { label: 'その他ご質問など:', value: otherQuestion },
            ].map(({ label, value }) => (
              <div key={label} className={styles.confirmContentItem}>
                <p className={styles.confirmContentItemLabel}>{label}</p>
                <p className={styles.confirmContentItemValue}>{value}</p>
              </div>
            ))}
          </div>
          <p className={styles.confirmTerm}>
            フォームを送信いただく前に、必ず
            <a
              href="https://terms.paidy.com/docs/consent-clause-regarding-personal-information.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              個人情報取扱に関する同意書
            </a>
            をお読みいただき、 <br />
            ご同意の上、ご送信ください。お問い合わせでお預かりいたしました個人情報は、
            <br className={styles.breaklineOnDesktop} />
            お問い合わせ対応のみに利用させていただきます。
          </p>
        </div>
        <div className={styles.confirmButtonContainer}>
          <button
            className={classnames(styles.button, {
              [styles.buttonLoading]: isLoading,
            })}
            onClick={submitDataHandler}
            disabled={isLoading}
          >
            申し込む
          </button>
          <button
            className={classnames(styles.button, styles.secondaryButton)}
            onClick={backHandler}
            disabled={isLoading}
          >
            修正
          </button>
        </div>
      </div>
    </div>
  );
};

const Success = ({ backHandler }) => {
  return (
    <div className={styles.successWrapper}>
      <h1>お申込みありがとうございました。</h1>
      <p>担当者より、3営業日以内に、ご連絡させていただきます。</p>
      <button
        className={classnames(styles.button, styles.blackButton)}
        onClick={backHandler}
      >
        閉じる
      </button>
    </div>
  );
};

const SixPayTwelvePayPage = () => {
  const [formState, setFormState] = useReducer(formReducer, {
    state: 'init',
    formData: {},
    isLoading: false,
    isSubmittingError: false,
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const submitDataHandler = () => {
    setFormState({ type: 'setLoading' });
    axios
      .post(CONTACT_US_ENDPOINT, {
        form_type: '6pay12pay',
        ...formState.formData,
      })
      .then((response) => {
        if (!response.data.id) {
          setFormState({ type: 'setSubmittingError' });
        }

        setFormState({ type: 'setSubmittingSuccess' });
      })
      .catch((error) => {
        console.log(error);
        setFormState({ type: 'setSubmittingError' });
      });
  };

  const backHandler = () => {
    setFormState({ type: 'setInit' });
    setTimeout(() => {
      setValue('paymentPlan', formState.formData.paymentPlan);
      setValue('companyName', formState.formData.companyName);
      setValue('shopName', formState.formData.shopName);
      setValue('managerName', formState.formData.managerName);
      setValue('managerEmail', formState.formData.managerEmail);
      setValue('otherQuestion', formState.formData.otherQuestion);

      const formElement = document.getElementById('form');

      if (formElement) {
        formElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const onSubmit = async (data) => {
    setFormState({ type: 'setConfirm', formData: data });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const closeTheWindow = () => {
    window.location.href = PAIDY_HOST;
  };

  const onError = (error) => {
    console.error(error);
  };

  const renderForm = () => {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.headerContent}>
            <h1>
              平均単価が最大<span className={styles.highlight}>8倍以上!</span>**
              <br className={styles.breaklineOnMobile} />
              高額商品の売上アップに。
            </h1>
            <h2>
              分割手数料無料*の
              <span className={styles.highlight}>6・12回あと払い</span>
            </h2>
            <div className={styles.heroImages}>
              {[
                {
                  src: SixPayImage,
                  alt: '6Pay',
                  text: '6回あと払いの平均単価は',
                  highlight: '4倍以上',
                },
                {
                  src: TwelvePayImage,
                  alt: '12Pay',
                  text: '12回あと払いの平均単価はおよそ',
                  highlight: '8倍',
                },
              ].map(({ src, alt, text, highlight }) => (
                <div key={alt} className={styles.heroImage}>
                  <img src={src} alt={alt} />
                  <p>
                    {text}
                    <span className={styles.highlight}>{highlight}</span>
                  </p>
                </div>
              ))}
            </div>
            <div className={styles.heroText}>
              <div className={styles.heroTextContent}>
                <p>
                  一括払いだとちょっと手の届かない商品も、6・12回あと払いなら今すぐ手に入れることができるので、平均単価は
                  <strong>最大8倍以上</strong>
                  **。高額商品の売上アップにつながります。
                </p>
                <br />
                <p>
                  お客様が分割払いを選択した場合も、加盟店様へは翌月一括振込いたします。
                </p>
                <p className={styles.note}>
                  *口座振替・銀行振込のみ{' '}
                  <span style={{ marginLeft: 28 }}>** 当社調べ</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.stepsContainer}>
          <Steps
            title={
              <>
                かんたん<span className={styles.highlight}>3ステップ</span>
                <br className={styles.breaklineOnMobile} />
                でお申し込み完了
              </>
            }
            steps={[
              {
                image: require('../../images/how_use_step1.svg'),
                info: '下記のお申し込みフォームよりお申し込みください。',
              },
              {
                image: require('../../images/how_use_step3.svg'),
                info: '経済条件をメールにてお伝えいたします。',
              },
              {
                image: require('../../images/how_use_step4a.svg'),
                info:
                  '提示した経済条件に同意いただければ、弊社より経済条件を記載した通知書を送付後、弊社にて設定します。',
              },
            ]}
            containerStyle={{
              paddingLeft: 32,
              paddingRight: 32,
            }}
            className={styles.steps}
            note="※ご利用開始は毎月１日になります。このため、お申し込みのタイミングによってはご利用開始が翌々月１日となるケースもございます。"
          />
        </div>
        <div id="form" className={styles.form}>
          <div className={styles.formContent}>
            <>
              <form
                className={styles.formWrapper}
                id="6payform"
                onSubmit={handleSubmit(onSubmit, onError)}
              >
                {Object.keys(errors).length > 0 && (
                  <p className={styles.requiredText}>
                    必須項目は必ず入力・選択してください
                  </p>
                )}

                <Controller
                  control={control}
                  name="paymentPlan"
                  rules={{ required: '必須項目です' }}
                  defaultValue="6回"
                  render={({ onChange, value }, { invalid }) => (
                    <>
                      <p>
                        お申し込みの分割回数をご選択ください。 ※必須
                        <br />
                        <small className={styles.notes}>
                          *12回あと払い単独のお申し込みはできません。
                        </small>
                      </p>

                      <div>
                        <RadioGroup
                          className={styles.payOtions}
                          name="paymentPlan"
                          value={value}
                          onChange={onChange}
                        >
                          <FormControlLabel
                            className={styles.payOptionLabel}
                            value="6回"
                            control={<StyledRadio />}
                            label="6回あと払い"
                          />
                          <FormControlLabel
                            className={styles.payOptionLabel}
                            value="6回・12回"
                            control={<StyledRadio />}
                            label="6回・12回あと払い"
                          />
                        </RadioGroup>
                        {invalid && (
                          <p className={styles.requiredFieldText}>
                            必須項目です
                          </p>
                        )}
                      </div>
                    </>
                  )}
                />

                <TextField
                  key="companyName"
                  label="会社名 ※必須"
                  fullWidth
                  margin="normal"
                  placeholder="例　○○株式会社"
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{ shrink: true }}
                  variant="filled"
                  className={classnames(styles.textInput, {
                    [styles.textInputError]: !!errors.companyName,
                  })}
                  name="companyName"
                  inputRef={register({ required: '必須項目です' })}
                  error={!!errors.companyName}
                  FormHelperTextProps={{
                    className: styles.requiredFieldText,
                  }}
                  helperText={errors.companyName?.message}
                />
                <TextField
                  key="shopName"
                  label="お申し込み加盟店名（ECサイト名） ※必須"
                  fullWidth
                  margin="normal"
                  placeholder="ECサイト名を入力してください"
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{ shrink: true }}
                  variant="filled"
                  className={classnames(styles.textInput, {
                    [styles.textInputError]: !!errors.shopName,
                  })}
                  name="shopName"
                  inputRef={register({ required: '必須項目です' })}
                  error={!!errors.shopName}
                  FormHelperTextProps={{
                    className: styles.requiredFieldText,
                  }}
                  helperText={errors.shopName?.message}
                />
                <TextField
                  key="managerName"
                  label="ご担当者名 ※必須"
                  fullWidth
                  margin="normal"
                  placeholder="名を入力してください"
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{ shrink: true }}
                  variant="filled"
                  className={classnames(styles.textInput, {
                    [styles.textInputError]: !!errors.managerName,
                  })}
                  name="managerName"
                  inputRef={register({ required: '必須項目です' })}
                  error={!!errors.managerName}
                  helperText={errors.managerName?.message}
                />

                <TextField
                  label="ご担当者メールアドレス ※必須"
                  fullWidth
                  margin="normal"
                  type="email"
                  placeholder="ベイディ@example.com"
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{ shrink: true }}
                  variant="filled"
                  className={classnames(styles.textInput, {
                    [styles.textInputError]: !!errors.managerEmail,
                  })}
                  name="managerEmail"
                  inputRef={register({
                    required: '必須項目です',
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: 'メールアドレスを正しく入力してください',
                    },
                  })}
                  error={!!errors.managerEmail}
                  FormHelperTextProps={{
                    className: styles.requiredFieldText,
                  }}
                  helperText={errors.managerEmail?.message}
                />

                <TextField
                  label="その他ご質問など"
                  fullWidth
                  margin="normal"
                  placeholder="回答を入力"
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{ shrink: true }}
                  variant="filled"
                  className={classnames(styles.textInput, {
                    [styles.textInputError]: !!errors.otherQuestion,
                  })}
                  name="otherQuestion"
                  inputRef={register()}
                />

                <button
                  className={classnames(styles.button, styles.submitBtn)}
                  type="submit"
                  disabled={formState.isLoading}
                >
                  今すぐ申し込む
                </button>
              </form>
            </>
          </div>
        </div>
      </>
    );
  };

  return (
    <Layout SEOProps={SEOProps} isMerchant hasNoFooter hasNoHeader>
      <div className={styles.pageContainer}>
        <nav className={styles.navHeader}>
          <div className={styles.navContent}>
            <img
              alt={'Paidy'}
              src={require('../../images/logo-color.svg')}
              width={104}
              height={29}
            />
            {formState.state === 'init' && (
              <a href="#form" className={styles.gotoBtn}>
                今すぐ申し込む
              </a>
            )}
          </div>
        </nav>

        <div className={styles.pageContent}>
          {(() => {
            switch (formState.state) {
              case 'success':
                return <Success backHandler={closeTheWindow} />;
              case 'confirm':
                return (
                  <Confirm
                    {...formState.formData}
                    backHandler={backHandler}
                    submitDataHandler={submitDataHandler}
                    isLoading={formState.isLoading}
                    isSubmittingError={formState.isSubmittingError}
                  />
                );
              default:
                return renderForm();
            }
          })()}
        </div>

        <Footer data={FOOTER_DATA} />
      </div>
    </Layout>
  );
};

export default SixPayTwelvePayPage;
